.wrapper {
    background-color: #FDFDFD;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

header {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    width: 100%;
}
.title {
    font-size: 24px;
}
.school-name {
    font-weight: 800;
}
.categories {
    font-size: 20px;
}
.address {
    margin-top: 12px;
}
.director {
    margin-top: 8px;
}
.tel {
    margin-left: 16px;
}
.divider {
    height: 1px;
    width: 100%;
    margin-top: 8px;
    margin-bottom: 8px;
    display: block;
    background: transparent;
    border: none;
    border-top: solid 1px #9D9CAF;
}

.btn-save {
    margin-top: 24px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #5cdc3c;
    color: #0f9404;
    font-weight: 600;
    font-size: 14px;
    border: 2px solid #0f9404;
    cursor: pointer;
}

.btn-start {
    margin-top: 24px;
    padding: 8px 16px;
    border-radius: 8px;
    background-color: #f3e260;
    color: #cca42b;
    font-weight: 600;
    font-size: 14px;
    border: 2px solid #cca42b;
    cursor: pointer;
}

.data-wrapper {
    margin-bottom: 8px;
}
.cursant-wrapper, .sign-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.cursant-wrapper {
    flex-wrap: wrap;
}
.examinator-wrapper {
    margin-bottom: 8px;
}
.sign-wrapper {
    margin-top: 8px;
}
.row2, .row3 {
    margin-left: 16px;
}
.label {
    font-size: 12px;
    margin-right: 8px;
}
input {
    height: 24px;
    font-size: 16px;
    border: 0;
    border-bottom: 1px solid #9D9CAF;
    text-align: center;
    text-transform: uppercase;
}

input:nth-child() {
    background-color: red;
}
#name-input {
    width: 360px;
}
#nr-input {
    width: 90px;
}
#data-input {
    width: 100px;
    text-transform: none;
}
#category-input {
    width: 60px;
}
#name-ex-input {
    width: 250px;
}
#sign-input {
    width: 150px;
}
#sign-input:disabled {
    background-color: white;
}
.error-input {
    outline: 1px solid red;
}
input::placeholder {
    font-size: 12px;
    color: #9D9CAF;
    font-style: oblique;
    text-transform: initial;
}
input:focus {
    outline: 0;
}
.exam-wrapper {
    margin-top: 24px;
}
.sign-wrapper {
    display: none;
}
iframe {
    border-width: 1px;
    border-color: rgba(157, 156, 175, 0.5);
    box-shadow: 0px 0px 5px 1px rgba(157, 156, 175, 0.5);
}

.modal-header {
    font-size: 24px;
    color: red;
    font-weight: 600;
    margin-bottom: 24px;
}
.modal-content {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 32px;
}
.modal-footer {
    display: flex;
    justify-content: space-around;
}
.btn {
    padding: 8px 16px;
    font-size: 14px;
    border: 0;
    border-radius: 4px;
    color: white;
    cursor: pointer;
}
#modify-btn {
    background-color: #F84F31;
}
#save-btn {
    background-color: #23C552;
}
footer {
    display: none;
}

@media print {
    html {
        -moz-transform: scale(0.8, 0.8);
        -ms-transform: scale(0.8);
        -webkit-transform: scale(0.8);
        transform: scale(0.8);
    }
    .btn-save {
       display: none;
    }
    .sign-wrapper {
        display: flex;
        margin-top: 16px;
        margin-bottom: 24px;
    }
    footer {
        position: fixed;
        bottom: 0;
        display: block;
        font-weight: bold;
    }
    header {
        margin-top: 200px;
        position: fixed;
        display: block;
        width: 100%;
        background-color: green;
    }
    @page { margin: 8px; }
}